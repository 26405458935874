<template>
  <div class="main">
    <el-form ref="form" :model="obj" :rules="rules" label-position="left" label-width="180px">
      <div class="top">
        <h2>学生基础信息:</h2>
        <el-form-item v-for="(i,n) in objArr" v-if="type==='4'?true:n!==4" :key="i.key" :label="i.label" :prop="i.key">
          <el-input v-if="n!==0" :placeholder="i.pld" :value="txt[i.keyName]"></el-input>
          <el-select v-else v-model="obj[i.key]" :loading="loading" :placeholder="i.pld" :remote-method="remoteMethod" filterable remote remote-show-suffix reserve-keyword @change="change">
            <el-option v-for="item in options" :key="item.student_id" :label="item.student_name" :value="item.student_id"/>
          </el-select>
        </el-form-item>
      </div>
      <div class="top">
        <h2>{{ txtObj[type].txt }}:</h2>
        <el-form-item v-for="i in objArr_" :key="i.key" :label="i.label" :prop="i.key">
          <el-input v-if="i.type === 'input'" v-model="obj[i.key]" :placeholder="i.pld"></el-input>
          <el-date-picker v-if="i.type=== 'time'" v-model="obj[i.key]" :placeholder="i.pld" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          <el-select v-if="i.type=== 'select'" v-model="obj[i.key]" :filterable="i.filterable" :placeholder="i.pld" @change="change_(i.key)">
            <el-option v-for="i_ in i.options" :key="i_[i.opValue]" :disabled="i_.isSelected" :label="i_[i.opLabel]" :value="i_[i.opValue]"/>
          </el-select>
          <el-input v-if="i.type === 'textarea'" v-model="obj[i.key]" :autosize="{minRows: 4}" :placeholder="i.pld" maxlength="300" show-word-limit style="width: 400px" type="textarea"></el-input>
        </el-form-item>
      </div>
      <div class="top">
        <el-button type="primary" @click="submitForm('form')">提交</el-button>
        <el-button @click="$router.back()">返回</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>

export default {
  _config: {"route": {"path": "add", "meta": {"title": "新增", style: 2}}},
  data() {
    return {
      type: -1,
      obj: {},
      txt: {},
      txtObj: {
        1: {txt: '学生留级信息', url: 'api/student/student-adjust/stay-down-add', addTxt: '提交后无法更改，是否确认学生留级信息无误？'},
        2: {txt: '学生休学信息', url: 'api/student/student-adjust/suspend-schooling-add', addTxt: '提交后无法更改，是否确认学生休学信息无误？'},
        3: {txt: '学生转学信息', url: 'api/student/student-adjust/transfer-add', addTxt: '提交后无法更改，是否确认学生转学信息无误？'},
        4: {txt: '学生退学信息', url: 'api/student/student-adjust/drop-out-add', addTxt: '提交后无法更改，是否确认学生退学？'},
      },
      objArr: [
        {label: '学生姓名', key: 'student_id', pld: '输入学生姓名'},
        {label: '校区', key: 'school_id', pld: '输入校区', keyName: 'school_name'},
        {label: '年级', key: 'grade_id', pld: '输入年级', keyName: 'grade_name'},
        {label: '行政班', key: 'class_id', pld: '输入行政班', keyName: 'class_name'},
        {label: '学生状态', key: 'student_status', pld: '输入学生状态', keyName: 'student_status'},
      ],
      objArr_: [],
      options: [],
      loading: false,
      rules: {
        student_id: [
          {required: true, message: '请输入学生姓名', trigger: 'change'},
        ],
        stay_down_grade_id: [
          {required: true, message: '请选择留级后转入年级', trigger: 'change'},
        ],
        stay_down_class_id: [
          {required: true, message: '请选择留级后转入行政班', trigger: 'change'},
        ],
        is_sign: [
          {required: true, message: '请选择是否需签署协议', trigger: 'change'},
        ],
        stay_down_reason: [
          {required: true, message: '请输入留级原因', trigger: 'change'},
        ],
        start_time: [
          {required: true, message: '请选择开始休学时间', trigger: 'change'},
        ],
        estimate_end_time: [
          {required: true, message: '请选择预计复学时间', trigger: 'change'},
        ],
        suspend_schooling_reason: [
          {required: true, message: '请输入休学原因', trigger: 'change'},
        ],
        transfer_school_id: [
          {required: true, message: '请选择转学校区', trigger: 'change'},
        ],
        transfer_time: [
          {required: true, message: '请选择转学时间', trigger: 'change'},
        ],
        transfer_reason: [
          {required: true, message: '请输入转学原因', trigger: 'change'},
        ],
        drop_out_time: [
          {required: true, message: '请选择退学时间', trigger: 'change'},
        ],
        drop_out_reason: [
          {required: true, message: '请选择退学原因', trigger: 'change'},
        ],
      }
    }
  },
  methods: {
    remoteMethod(val) {
      this.loading = true
      let params = {
        type: this.type,
        student_name: val
      }
      this.$api2.get('api/student/student-adjust/select-student', {params}).then(res => {
        this.loading = false
        this.options = res.data.data
      })
    },
    change(val) {
      if (this.obj['stay_down_grade_id']) this.obj['stay_down_grade_id'] = ''
      if (this.obj['stay_down_class_id']) this.obj['stay_down_class_id'] = ''
      if (this.obj['is_sign']) this.obj['is_sign'] = ''
      if (this.obj['transfer_school_id']) this.obj['transfer_school_id'] = ''
      this.obj.school_id = this.options.find(item => item.student_id === val).school_id
      this.obj.grade_id = this.options.find(item => item.student_id === val).grade_id
      this.obj.class_id = this.options.find(item => item.student_id === val).class_id
      this.txt = this.options.find(item => item.student_id === val)
      if (this.type === '1') {
        this.$api2.get('api/student/student-adjust/stay-down-optional', {params: {student_id: val}}).then(res => {
          let {data} = res.data
          switch (Number(this.type)) {
            case 1:
              this.objArr_[0].options = data.grade
              this.objArr_[2].options = data.sign
              break

          }
        })
      }
      if (this.type === '3') {
        this.objArr_[0].options.forEach(option => {
          option.isSelected = Number(option.id) === Number(this.obj.school_id)
        });
      }
    },
    change_(key) {
      if (key === 'stay_down_grade_id') {
        if (this.obj['stay_down_class_id']) this.obj['stay_down_class_id'] = ''
        this.objArr_[1].options = this.objArr_[0].options.find(item => item.grade_id === this.obj.stay_down_grade_id).class
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(this.txtObj[this.type].addTxt, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '在看看',
            type: 'warning'
          }).then(() => {
            let url = this.txtObj[this.type].url
            this.$api2.post(url, this.obj).then(res => {
              if (Number(res.data.status) !== 0) return
              this.$message.success('提交成功')
              this.$router.back()
              this.$store.commit("setPage", 1);
            })
          }).catch();

        }
      });
    },
  },
  created() {
    this.type = this.$route.query.id
    switch (Number(this.type)) {
      case 1:
        this.objArr_ = [
          {label: '留级后转入年级', key: 'stay_down_grade_id', pld: '请选择留级后转入年级', type: 'select', options: [], opLabel: 'grade_name', opValue: 'grade_id'},
          {label: '留级后转入行政班', key: 'stay_down_class_id', pld: '请选择留级后转入行政班', type: 'select', options: [], opLabel: 'class_name', opValue: 'class_id', filterable: true},
          {label: '是否需签署协议', key: 'is_sign', pld: '请选择是否需签署协议', type: 'select', options: [], opLabel: 'sign_name', opValue: 'is_sign'},
          {label: '留级原因', key: 'stay_down_reason', pld: '请输入留级原因', type: 'textarea'},
        ]
        break
      case 2:
        this.objArr_ = [
          {label: '开始休学时间', key: 'start_time', pld: '请选择开始休学时间', type: 'time'},
          {label: '预计复学时间', key: 'estimate_end_time', pld: '请选择预计复学时间', type: 'time'},
          {label: '休学原因', key: 'suspend_schooling_reason', pld: '请输入休学原因', type: 'textarea'},
        ]
        break
      case 3:
        this.objArr_ = [
          {label: '转学校区', key: 'transfer_school_id', pld: '请选择转学校区', type: 'select', options: [], opLabel: 'school_name', opValue: 'id'},
          {label: '转学时间', key: 'transfer_time', pld: '请选择转学时间', type: 'time'},
          {label: '转学原因', key: 'transfer_reason', pld: '请输入转学原因', type: 'textarea'},
        ]
        // this.$_axios.get("/site/school").then(res => {
        //   let {data} = res.data;
        //   this.objArr_[0].options = data
        // })
        this.$api2.get('api/common/screen/school').then(res => {
          this.objArr_[0].options = res.data.data
        })
        break
      case 4:
        this.objArr_ = [
          {label: '退学时间', key: 'drop_out_time', pld: '请选择退学时间', type: 'time'},
          {label: '退学原因', key: 'drop_out_reason', pld: '请选择退学原因', type: 'select', options: [], opLabel: 'name', opValue: 'id'},
          {label: '备注', key: 'drop_out_remarks', pld: '请输入', type: 'textarea'},
        ]
        this.$api2.get('api/student/student-adjust/filter').then(res => {
          this.objArr_[1].options = res.data.data.drop_out_reason
        })
        break
    }
  },
}
</script>

<style lang="scss" scoped>
.main {
  padding-right: 12px;
  box-sizing: border-box;
}

h2 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 12px;
  margin-bottom: 24px;
}

.top {
  min-height: 18px;
  background: #FFF;
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 24px;
}
</style>
